// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\gatsby\\love\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-single-post-js": () => import("C:\\gatsby\\love\\src\\templates\\single-post.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-post-list-js": () => import("C:\\gatsby\\love\\src\\templates\\post-list.js" /* webpackChunkName: "component---src-templates-post-list-js" */),
  "component---src-templates-tags-page-js": () => import("C:\\gatsby\\love\\src\\templates\\tags-page.js" /* webpackChunkName: "component---src-templates-tags-page-js" */),
  "component---src-templates-tag-posts-js": () => import("C:\\gatsby\\love\\src\\templates\\tag-posts.js" /* webpackChunkName: "component---src-templates-tag-posts-js" */),
  "component---src-templates-author-posts-js": () => import("C:\\gatsby\\love\\src\\templates\\author-posts.js" /* webpackChunkName: "component---src-templates-author-posts-js" */),
  "component---src-pages-404-js": () => import("C:\\gatsby\\love\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arama-motoru-optimizasyonu-js": () => import("C:\\gatsby\\love\\src\\pages\\arama-motoru-optimizasyonu.js" /* webpackChunkName: "component---src-pages-arama-motoru-optimizasyonu-js" */),
  "component---src-pages-blog-js": () => import("C:\\gatsby\\love\\src\\pages\\blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-domain-js": () => import("C:\\gatsby\\love\\src\\pages\\domain.js" /* webpackChunkName: "component---src-pages-domain-js" */),
  "component---src-pages-e-ticaret-js": () => import("C:\\gatsby\\love\\src\\pages\\e-ticaret.js" /* webpackChunkName: "component---src-pages-e-ticaret-js" */),
  "component---src-pages-google-adwords-js": () => import("C:\\gatsby\\love\\src\\pages\\google-adwords.js" /* webpackChunkName: "component---src-pages-google-adwords-js" */),
  "component---src-pages-hosting-js": () => import("C:\\gatsby\\love\\src\\pages\\hosting.js" /* webpackChunkName: "component---src-pages-hosting-js" */),
  "component---src-pages-iletisim-js": () => import("C:\\gatsby\\love\\src\\pages\\iletisim.js" /* webpackChunkName: "component---src-pages-iletisim-js" */),
  "component---src-pages-index-js": () => import("C:\\gatsby\\love\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kurumsal-js": () => import("C:\\gatsby\\love\\src\\pages\\kurumsal.js" /* webpackChunkName: "component---src-pages-kurumsal-js" */),
  "component---src-pages-marka-tescili-js": () => import("C:\\gatsby\\love\\src\\pages\\marka-tescili.js" /* webpackChunkName: "component---src-pages-marka-tescili-js" */),
  "component---src-pages-referanslar-js": () => import("C:\\gatsby\\love\\src\\pages\\referanslar.js" /* webpackChunkName: "component---src-pages-referanslar-js" */),
  "component---src-pages-team-js": () => import("C:\\gatsby\\love\\src\\pages\\team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-teklif-iste-js": () => import("C:\\gatsby\\love\\src\\pages\\teklif-iste.js" /* webpackChunkName: "component---src-pages-teklif-iste-js" */),
  "component---src-pages-web-tasarim-js": () => import("C:\\gatsby\\love\\src\\pages\\web-tasarim.js" /* webpackChunkName: "component---src-pages-web-tasarim-js" */)
}

