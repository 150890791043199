module.exports = [{
      plugin: require('C:/gatsby/love/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/gatsby/love/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/gatsby/love/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-41364010-1","head":false},
    },{
      plugin: require('C:/gatsby/love/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
